.whyjoinus {
  min-height: 110vh;
  position: relative;
}

.whyjoinus .line-styling {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whyjoinus .line-styling .style-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.whyjoinus .line-styling .style-line {
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.whyjoinus-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 8rem;
  height: fit-content;
}

.whyjoinus-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: 0.5;
  width: 100%;
  height: fit-content;
}

.whyjoinus-description>h2 {
  margin-bottom: 40px;
  font-size: 3.5rem;
  font-family: var(--primaryFont);
}

.whyjoinus-description>p {
  font-size: 1.25rem;
  color: #e0d9d9;
  font-family: var(--primaryFont);
}

.whyjoinus-img {
  margin-top: 40px;
  pointer-events: none;
}

.whyjoinus-img>img {
  width: 380px;
  flex: 0.5;
}

@media (min-width: 992px) and (max-width: 1380px) {

  .whyjoinus-description>h2 {
    font-size: 3.3rem;
    margin-bottom: 30px;
  }

  .whyjoinus-description>p {
    font-size: 1.15rem;
  }

  .whyjoinus-img>img {
    width: 300px;
  }
}

@media screen and (max-width: 992px) {

  .whyjoinus .line-styling {
    top: 20px;
  }

  .whyjoinus-body {
    padding-top: 2rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
  }

  .whyjoinus-description {
    flex: 0.6;
    box-sizing: border-box;
    /* padding: 5% 10%; */
    padding: 20px;

  }

  .whyjoinus-description>h2 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .whyjoinus-description>p {
    font-size: 1.1rem;
  }

  .whyjoinus-img>img {
    width: 250px;
    flex: 0.4;
  }

}

@media screen and (max-width: 800px) {
  .whyjoinus .line-styling .style-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .whyjoinus-description>h2 {
    align-self: center;
  }

}

@media screen and (max-width: 600px) {

  .whyjoinus .line-styling .style-circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .whyjoinus .line-styling .style-line {
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .whyjoinus-description>h2 {
    font-size: 2.5rem;
  }

  .whyjoinus-description>p {
    font-size: 0.95rem;
  }

  .whyjoinus-img>img {
    width: 200px;
  }
}

/* Ensure the cursor changes to a pointer for all anchor tags within .whyjoinus-description */
.whyjoinus-description a {
  color: inherit;
  /* Ensure the link color matches the surrounding text */
  text-decoration: none;
  /* Optional: Remove underline from the link */
  cursor: pointer;
  /* Ensures the cursor changes to a hand */
}

/* Optionally, add hover effects */
.whyjoinus-description a:hover {
  text-decoration: underline;
  /* Optional: Adds underline on hover for better visibility */
}
