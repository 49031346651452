body {
    margin: 0;
    /* Remove default body margin */
}

.container {
    display: flex;
    justify-content: center;
    padding: 0;
    /* Remove padding */
    background-color: #f8f9fa;
    /* Light background */
}

.menu {
    display: flex;
    flex-wrap: wrap;
    /* Allow wrapping to handle smaller screens */
    width: 100%;
    /* Full width */
}

.menu button {
    flex: 1;
    /* Make each button take equal width */
    background-color: #28a745;
    /* Green color */
    color: white;
    border: none;
    padding: 15px;
    /* Adjust padding for better touch targets */
    cursor: pointer;
    border-radius: 0px;
    transition: background-color 0.3s;
    box-sizing: border-box;
    /* Include padding and border in element’s total width and height */
}

.menu button:hover {
    background-color: #218838;
    /* Darker green on hover */
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .menu button {
        font-size: 14px;
        /* Adjust font size for smaller screens */
        padding: 10px;
        /* Adjust padding for better fit on smaller screens */
    }
}

@media (max-width: 480px) {
    .menu {
        flex-direction: column;
        /* Stack buttons vertically on very small screens */
    }

    .menu button {
        width: 100%;
        /* Ensure buttons take full width in column layout */
    }
}
